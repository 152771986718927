import { Alert, Card, Input, List, Space, Spin } from "antd";
import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";

const PUBLIC_PROJECTS = gql`
  query GetProjects {
    result: currentUser {
      projects {
        name
        url
      }
    }
  }
`;

const MAX_COUNT_BEFORE_SEARCH_FILTER = 10;

export const ProjectPicker = () => {
  const { loading, error, data } = useQuery<{
    result: { projects: { name: string; url: string }[] };
  }>(PUBLIC_PROJECTS);
  const [searchValue, setSearchValue] = useState<string>("");
  const projects = data?.result.projects ?? [];
  const filteredProjects = projects.filter((item) =>
    item.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  filteredProjects.sort((a, b) => a.name.localeCompare(b.name));

  /**
   * Function that handles search input and filters projects based on it
   * @param e
   */
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  if (typeof data === "undefined" || loading) {
    return <Spin style={{ width: "100%" }} />;
  }

  if (error) {
    return <Alert type="error" message={error.message} />;
  }

  if (data && projects.length === 1) {
    window.location.href = projects[0].url;
    return <></>;
  }

  return (
    <>
      <Card
        title={
          <div className="project-list-title" style={{ textAlign: "center" }}>
            Vyberte požadovaný projekt
          </div>
        }
      >
        {!loading && projects.length > 0 && (
          <Space direction={"vertical"} style={{ width: "100%" }}>
            {projects.length > MAX_COUNT_BEFORE_SEARCH_FILTER && (
              <Input
                placeholder="Vyhledejte projekt..."
                onChange={handleSearchChange}
              />
            )}
            <List
              loading={loading}
              className="project-list"
              style={{
                minHeight: 200,
                overflowY: "auto",
              }}
              bordered={true}
              dataSource={filteredProjects || []}
              renderItem={(item) => (
                <List.Item
                  onClick={() => {
                    window.location.href = item.url;
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {item.name}
                </List.Item>
              )}
            />
          </Space>
        )}

        {!loading && projects.length === 0 && (
          <Alert
            type="warning"
            message="Je nám líto, ale nemáte přístup do žádného projektu."
          />
        )}
      </Card>
    </>
  );
};
